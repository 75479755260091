.credit-pack-container {
  margin-bottom: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.credit-pack-remaining {
  font-size: 0.75rem;
}

.credit-pack-remaining-caption {
  margin-bottom: 15px;
  font-size: 16px;
}

.credit-pack-zero-state {
  display: flex;
  flex-direction: column;
}

.credit-pack-zero-state h4 {
  margin-bottom: 0;
}

.credit-pack-carousel {
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-control-prev {
  left: -12px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.carousel-control-next {
  right: -12px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
