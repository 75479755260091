/* Custom styles for our editor */

/* Remove default border */
/* Start */
.ql-toolbar.ql-snow {
  background-color: transparent;
  border: 0px solid transparent;
  border-bottom: 1px solid #d0d5dd;
}
.ql-container.ql-snow {
  background-color: transparent;
  border: 0px solid transparent;
}
/* Finish */

/* Remove blue focus outline (ring) in Firefox and other browsers */
.ql-container:focus,
.ql-toolbar:focus,
.ql-editor:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* General style for buttons, dropdown labels, and options on hover or active */
/* Start */
.ql-toolbar .ql-formats button:hover,
.ql-toolbar .ql-formats button.ql-active,
.ql-toolbar .ql-picker-label:hover,
.ql-toolbar .ql-picker-label.ql-active,
.ql-toolbar .ql-picker-options .ql-picker-item:hover,
.ql-toolbar .ql-picker-options .ql-picker-item.ql-selected {
  color: #5012b0 !important;
}
/* Style for icons (stroke and fill) in buttons, dropdown labels, and options */
.ql-toolbar .ql-formats button:hover .ql-stroke,
.ql-toolbar .ql-formats button.ql-active .ql-stroke,
.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-toolbar .ql-picker-options .ql-picker-item:hover .ql-stroke,
.ql-toolbar .ql-picker-options .ql-picker-item.ql-selected .ql-stroke {
  stroke: #5012b0 !important;
}
.ql-toolbar .ql-formats button:hover .ql-fill,
.ql-toolbar .ql-formats button.ql-active .ql-fill,
.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-toolbar .ql-picker-options .ql-picker-item:hover .ql-fill,
.ql-toolbar .ql-picker-options .ql-picker-item.ql-selected .ql-fill {
  fill: #5012b0 !important;
}
/* Finish */

/* Some fixes because of global styles in our web */

/* Fix for the "h6, .h6" global style */
.ql-snow .ql-editor h6 {
  font-size: 0.67em !important;
  text-transform: unset;
}
