.range::-webkit-slider-thumb {
  @apply bg-[#ffffff] h-6 w-6 border-gray-200 dark:bg-gray-950 dark:border-gray-800 border;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #d0d5dd;
}
.ReactCrop__crop-selection {
  @apply bg-secondary-500;
  border: none;
  fill: transparent;
  background-blend-mode: multiply;
  mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg' stroke='%23fff' fill='transparent'%3E%3Crect width='100%25' height='100%25' stroke-width='8' stroke-dasharray='50%25' stroke-dashoffset='25%25' stroke-linecap='round' /%3E%3C/svg%3E%0A");
}
