.user-calendar-date-select {
  margin: auto 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 0.625rem;
  font-family: 'Poppins';
  font-weight: 400;
  color: #fff;
}

.user-calendar-date-select > div {
  height: 100%;
}

.user-calendar-date-select.btn-outline-primary input {
  color: #31436e !important;
}

.user-calendar-date-select.btn-outline-primary:hover input {
  color: #6186db !important;
}

.user-calendar-date-select input:focus {
  outline: transparent;
}

.user-calendar-date-select.btn-primary {
  transform: unset !important;
}

.position-right .rdtPicker {
  right: -25px;
}

.position-right .rdtPicker:before {
  display: none;
}

.position-right .rdtPicker:after {
  display: none;
}
