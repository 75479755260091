.scrollbarCustom {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #E4E7EC transparent;
}

.scrollbarCustom::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.scrollbarCustom::-webkit-scrollbar-thumb {
  background-color: #E4E7EC;
  border-radius: 10rem;
}

.scrollbarCustom::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10rem;
}