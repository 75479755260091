@tailwind base;

@layer base {
  /* Any colors changes here should be applied to resetTailwindColors() */
  :root {
    --primary-color-25: 249 250 255; /* #f9faff */
    --primary-color-50: 247 248 255; /* #f7f8ff */
    --primary-color-100: 216 219 255; /* #d8dbff */
    --primary-color-200: 182 188 252; /* #b6bcfc */
    --primary-color-300: 64 70 129; /* #404681 */
    --primary-color-400: 51 58 120; /* #333a78 */
    --primary-color-500: 38 46 112; /* #262e70 */
    --primary-color-600: 26 33 104; /* #1a2168 */
    --primary-color-700: 0 9 87; /* #000957 */
    --primary-color-800: 0 9 87; /* #000957 */
    --primary-color-900: 0 8 76; /* #00084c */

    --secondary-color-50: 243 236 253; /* #f3ecfd */
    --secondary-color-100: 231 218 251; /* #e7dafb */
    --secondary-color-200: 207 181 247; /* #cfb5f7 */
    --secondary-color-300: 183 144 244; /* #b790f4 */
    --secondary-color-400: 159 107 240; /* #9f6bf0 */
    --secondary-color-500: 135 70 236; /* #8746ec */
    --secondary-color-600: 103 23 227; /* #6717e3 */
    --secondary-color-700: 80 18 176; /* #5012b0 */
    --secondary-color-800: 57 13 125; /* #390d7d */
    --secondary-color-900: 34 8 74; /* #22084a */
    --secondary-color-950: 22 5 49; /* #160531 */

    --gray-color-0: 255 255 255; /* #ffffff */
    --gray-color-25: 252 252 253; /* #fcfcfd */
    --gray-color-50: 249 250 251; /* #f9fafb */
    --gray-color-100: 242 244 247; /* #f2f4f7 */
    --gray-color-200: 228 231 236; /* #E4E7EC */
    --gray-color-300: 208 213 221; /* #d0d5dd */
    --gray-color-400: 152 162 179; /* #98a2b3 */
    --gray-color-500: 102 112 133; /* #667085 */
    --gray-color-600: 71 84 103; /* #475467 */
    --gray-color-700: 52 64 84; /* #344054 */
    --gray-color-800: 29 41 57; /* #1d2939 */
    --gray-color-900: 16 24 40; /* #101828 */
    --gray-color-950: 16 24 40; /* #0C111D */

    --success-color-25: 246 254 249; /* #f6fef9 */
    --success-color-50: 236 253 243; /* #ecfdf3 */
    --success-color-100: 209 250 223; /* #d1fadf */
    --success-color-200: 166 244 197; /* #a6f4c5 */
    --success-color-300: 108 233 166; /* #6ce9a6 */
    --success-color-400: 50 213 131; /* #32d583 */
    --success-color-500: 18 183 106; /* #12b76a */
    --success-color-600: 3 152 85; /* #039855 */
    --success-color-700: 2 122 72; /* #027a48 */
    --success-color-800: 5 96 58; /* #05603a */
    --success-color-900: 5 79 49; /* #054f31 */

    --error-color-25: 255 251 250; /* #fffbfa */
    --error-color-50: 254 243 242; /* #fef3f2 */
    --error-color-100: 254 228 226; /* #fee4e2 */
    --error-color-200: 254 205 202; /* #fecdca */
    --error-color-300: 253 162 155; /* #fda29b */
    --error-color-400: 249 112 102; /* #f97066 */
    --error-color-500: 240 68 56; /* #f04438 */
    --error-color-600: 217 45 32; /* #d92d20 */
    --error-color-700: 180 35 24; /* #b42318 */
    --error-color-800: 145 32 24; /* #912018 */
    --error-color-900: 122 39 26; /* #7a271a */
    --error-color-950: 40 5 3; /* #280503 */

    --warning-color-25: 255 252 245; /* #fffcf5 */
    --warning-color-50: 255 250 235; /* #fffaeb */
    --warning-color-100: 254 240 199; /* #fef0c7 */
    --warning-color-200: 254 223 137; /* #fedf89 */
    --warning-color-300: 254 200 75; /* #fec84b */
    --warning-color-400: 253 176 34; /* #fdb022 */
    --warning-color-500: 247 144 9; /* #f79009 */
    --warning-color-600: 220 104 3; /* #dc6803 */
    --warning-color-700: 181 71 8; /* #b54708 */
    --warning-color-800: 147 55 13; /* #93370d */
    --warning-color-900: 122 46 14; /* #7a2e0e */

    --secondary-color-50: 243 236 253; /* #f3ecfd */
    --secondary-color-100: 231 218 251; /* #e7dafb */
    --secondary-color-200: 207 181 247; /* #cfb5f7 */
    --secondary-color-300: 183 144 244; /* #b790f4 */
    --secondary-color-400: 159 107 240; /* #9f6bf0 */
    --secondary-color-500: 135 70 236; /* #8746ec */
    --secondary-color-600: 103 23 227; /* #6717e3 */
    --secondary-color-700: 80 18 176; /* #5012b0 */
    --secondary-color-800: 57 13 125; /* #390d7d */
    --secondary-color-900: 34 8 74; /* #22084a */
    --secondary-color-950: 22 5 49; /* #160531 */
    --ring-color: rgb(59 130 246 / 0.5);

    --lightblue-color-50: 240 249 255; /* #F0F9FF */
    --lightblue-color-100: 224 242 254; /* #E0F2FE */
    --lightblue-color-200: 185 230 254; /* #B9E6FE */
    --lightblue-color-300: 124 212 253; /* #7CD4FD */
    --lightblue-color-400: 54 191 250; /* #36BFFA */
    --lightblue-color-500: 11 165 236; /* #0BA5EC */
    --lightblue-color-600: 2 106 162; /* #026AA2 */
    --lightblue-color-700: 0 134 201; /* #0086C9 */
    --lightblue-color-700: 6, 89, 134; /* #065986 */
    --lightblue-color-900: 11, 74, 111; /* #0B4A6F */
  }

  /*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

  *,
  ::before,
  ::after {
    box-sizing: border-box; /* 1 */
    border-width: 0; /* 2 */
    border-style: solid; /* 2 */
    border-color: theme("borderColor.DEFAULT", currentColor); /* 2 */
  }

  ::before,
  ::after {
    --tw-content: "";
  }

  /*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
*/

  html {
    line-height: 1.5; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -moz-tab-size: 4; /* 3 */
    tab-size: 4; /* 3 */
    font-family: theme(
      "fontFamily.sans",
      ui-sans-serif,
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      "Helvetica Neue",
      Arial,
      "Noto Sans",
      sans-serif,
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto Color Emoji"
    ); /* 4 */
  }

  /*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

  body {
    margin: 0; /* 1 */
    line-height: inherit; /* 2 */
    touch-action: none;
  }

  /*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/

  hr {
    height: 0; /* 1 */
    color: inherit; /* 2 */
    border-top-width: 1px; /* 3 */
  }

  /*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

  abbr:where([title]) {
    text-decoration: underline dotted;
  }

  /*
Remove the default font size and weight for headings.
*/

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  /*
Reset links to optimize for opt-in styling instead of opt-out.
*/

  a {
    color: inherit;
    text-decoration: inherit;
  }

  /*
Add the correct font weight in Edge and Safari.
*/

  b,
  strong {
    font-weight: bolder;
  }

  /*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/

  code,
  kbd,
  samp,
  pre {
    font-family: theme(
      "fontFamily.mono",
      ui-monospace,
      SFMono-Regular,
      Menlo,
      Monaco,
      Consolas,
      "Liberation Mono",
      "Courier New",
      monospace
    ); /* 1 */
    font-size: 1em; /* 2 */
  }

  /*
Add the correct font size in all browsers.
*/

  small {
    font-size: 80%;
  }

  /*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  /*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/

  table {
    text-indent: 0; /* 1 */
    border-color: inherit; /* 2 */
    border-collapse: collapse; /* 3 */
  }

  /*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: inherit; /* 1 */
    color: inherit; /* 1 */
    margin: 0; /* 2 */
    padding: 0; /* 3 */
  }

  /*
Remove the inheritance of text transform in Edge and Firefox.
*/

  button,
  select {
    text-transform: none;
  }

  /*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button; /* 1 */
    background-image: none; /* 2 */
  }

  /*
Use the modern Firefox focus style for all focusable elements.
*/

  :-moz-focusring {
    outline: auto;
  }

  /*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

  :-moz-ui-invalid {
    box-shadow: none;
  }

  /*
Add the correct vertical alignment in Chrome and Firefox.
*/

  progress {
    vertical-align: baseline;
  }

  /*
Correct the cursor style of increment and decrement buttons in Safari.
*/

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  /*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

  [type="search"] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }

  /*
Remove the inner padding in Chrome and Safari on macOS.
*/

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  /*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }

  /*
Add the correct display in Chrome and Safari.
*/

  summary {
    display: list-item;
  }

  /*
Removes the default spacing and border for appropriate elements.
*/

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  legend {
    padding: 0;
  }

  ol,
  ul,
  menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  /*
Prevent resizing textareas horizontally by default.
*/

  textarea {
    resize: vertical;
  }

  /*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

  input::placeholder,
  textarea::placeholder {
    opacity: 1; /* 1 */
    color: theme("colors.gray.400", #9ca3af); /* 2 */
  }

  /*
Set the default cursor for buttons.
*/

  button,
  [role="button"] {
    cursor: pointer;
  }

  /*
Make sure disabled buttons don't get the pointer cursor.
*/
  :disabled {
    cursor: default;
  }

  /*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block; /* 1 */
    vertical-align: middle; /* 2 */
  }

  /*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  /*
Ensure the default browser behavior of the `hidden` attribute.
*/

  [hidden] {
    display: none;
  }
}

@tailwind components;
@tailwind utilities;

:root {
  --background-color: transparent;
}

#root {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: row;
  background-color: var(--background-color);
  position: relative;
  overflow: hidden;
}

.rt-tbody,
.rt-table {
  overflow: visible !important;
}

@layer utilities {
  .h-screen {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
  @layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

input[type="checkbox"]::before {
  position: absolute;
  content: "";
  display: block;
  top: 1px;
  left: 6px;
  width: 8px;
  height: 12px;
  border-style: solid;
  border-radius: 1px;
  border-color: white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
input[type="checkbox"]:checked::before {
  opacity: 1;
}
input[type="checkbox"]:checked {
  background-color: #6717e3;
  border: 1px solid #6717e3;
}

input[type="checkbox"] {
  appearance: none;
  position: relative;
  min-width: 1.3rem;
  min-height: 1.3rem;
  background-color: white;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  outline: 0;
  cursor: pointer;
}

.form-group:has(.invert-parent) {
  display: flex;
  flex-direction: column-reverse;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e4e7ec;
  border-radius: 4px;
  width: 8px;
  height: 8px;
}
.dark ::-webkit-scrollbar-thumb {
  background: #344054;
}

::-webkit-scrollbar-thumb:hover {
  background: #d0d5dd;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.rdp-root {
  --rdp-accent-color: rgb(var(--secondary-color-500));
  --rdp-range_start-color: rgb(var(--secondary-color-500));
  --rdp-range_end-color: rgb(var(--secondary-color-500));
  --rdp-font-family: "Manrope";
}
.dark .rdp-dropdown {
  background-color: rgb(var(--gray-color-800));
}
@media not all and (min-width: 1024px) {
  ::-webkit-scrollbar {
    display: none;
  }
}
